export default {
  groupTab: 'Группа профилей телефона',
  num: 'Количество окружений',
  creatEnv: 'Создать',
  editEnv: 'Редактировать окружение',
  computingSet: 'Настройки вычислительной мощности',
  computingSetTip: 'После создания профиля тип изменить нельзя.',
  computingSetChineTip: 'Можно использовать только в сети материкового Китая. После создания изменения невозможны.',
  openEnvCombo: 'Активировать',
  computingManage: 'вычисления',
  closeEnvTitle: 'закрытие окружения',
  batchUpdateProxy: 'Пакетное обновление прокси',
  batchCloseEnv: 'Пакетное закрытие окружения',
  editNameWithSeq1: 'Изменить имя среды (номер: {seq})',
  editConfig: 'Изменить конфигурацию окружения',
  openEnv: 'Открытие окружения',
  envExpired: 'Окружение истекло',
  batchOpenEnv: 'Пакетное закрытие выбранных окружений',
  batchAllCloseEnv: 'Пакетное закрытие всех окружений',
  todayPhone: ' Сегодня',
  envName: 'Название окружения',
  name: 'Имя',
  envRmark: 'Примечание',
  lastUpdateTime: 'обновление',
  model: 'Марка',
  createPeople: 'Создатель',
  pkgEnvironment: 'Мобильные окружения: {count}',
  buy: 'Купить',
  openStatus: 'открытое состояние',
  totalEnvironment: 'Общее количество сред: {count}',
  pleaseSelect: 'Выберите среду',
  customEnvName: 'Имя пользовательской среды',
  nameTip: 'Назовите эту среду для будущих повседневных управленческих операций!',
  groupSelectTips:
    'Примечание: Если выбрана группа из другого аккаунта, эта среда будет перемещена в группу этого аккаунта (владение средой будет принадлежать другому аккаунту).',
  computeType0: `В первую очередь будет использоваться «Ежемесячные вычислительные мощности». Если они недоступны, будут использованы «Временные вычислительные мощности».`,
  computeType1:
    ' Единица тарификации: {num} юаня/15 минут (максимум {maxNum} юаней в день). При выключении мощности освобождаются, и тарификация прекращается; при приостановке тарификация продолжается.',
  computeType2:
    ' Автоматически привязывается доступная ежемесячная вычислительная мощность; один профиль использует одну ежемесячную мощность. При выключении мощность можно отвязать для использования другими профилями.',
  computeType3:
    'Оснащен различными датчиками для более реалистичного профиля. Необходимо привязать к Ежемесячным вычислительным мощностям для использования; тип можно отвязать только после истечения срока действия профиля.',
  remarkPlaceholder: 'Пожалуйста, заполните заметки',
  ruleNameMsg: 'Наименование среды до 30 символов',
  billingProgressTip: 'Использование мощности, продолжается оплата',
  allPhoneClosed: 'Все среды закрыты.',
  boundComTip: 'Привязанная ежемесячная вычислительная мощность: {name}',
  closePhone: 'Среда закрыта.',
  checkedHasClosed: 'Выбранная среда закрыта.',
  deleteTip: 'После удаления мобильная среда не может быть восстановлена. Вы уверены, что хотите полностью удалить мобильную среду?',
  batchDelect: 'Массовое удаление сред',
  deleteCompletelybrowser: 'Полностью удалить среду',
  computingStatus: 'вычислительной',
  boundComputingTips:
    'Выключить и отвязать ежемесячные вычислительные мощности; отвязанные мощности могут быть использованы другими профилями.',
  releaseComputingTips: 'Выключение и прекращение тарификации.',
  billingProgress: 'Оплата',
  closeEnv: 'Освободить мощность',
  releaseComputing: 'Освободить мощность',
  closeEnvStopCharging: 'Нажмите "Подтвердить", чтобы освободить вычислительные мощности и прекратить тарификацию. Подтвердить выключение?',
  closeEnvUnbindComputing:
    'Нажмите "Подтвердить", чтобы отвязать ежемесячные вычислительные мощности и выключить устройство. Отвязанные мощности могут быть использованы другими профилями. Подтвердить выключение?',
  temporarilyClosed: 'Приостановка',
  temporarilyClosed1: 'Приостановка',
  closeEnvBtn2: 'Выключение',
  closeEnvBtn1: 'Выключение',
  temporarilyClosedTip1:
    '<span class="text-negative">Выключение:</span> Закрыть окно профиля и выключить облачный телефон. Отвязать текущие привязанные ежемесячные вычислительные мощности для использования другими профилями!',
  temporarilyClosedTip2:
    "<span class='text-negative'>Выключение:</span> Закрыть окно профиля и выключить облачный телефон. Освободить временные вычислительные мощности и прекратить тарификацию!",
  unbindComputing: 'Отвязать мощность',
  closeEnvTip1: 'Выключить и отвязать ежемесячные вычислительные мощности. Профиль может запуститься немного медленнее после выключения!',
  closeEnvTip2:
    'Выключение и освобождение временных вычислительных мощностей. Профиль может запуститься немного медленнее после выключения!',
  notMultipleOpen: 'Текущая среда не позволяет открывать несколько аккаунтов одновременно.',
  searchNamePlaceholder: 'Поиск по названию среды',
  temporarilyClosedTip1_1:
    "<span class='text-primary'>Приостановка:</span> Закрыть только окно профиля; ежемесячные вычислительные мощности остаются привязанными, и облачный телефон продолжает работать.",
  temporarilyClosedTip2_1:
    "<span class='text-primary'>Приостановка:</span> Закрыть только окно профиля; временные вычислительные мощности продолжают тарифицироваться, и профиль телефона продолжает работать!",
  changeConfirm: `
    <p class="text-red text-bold">После снижения тарифного плана:</p>
    <p class="text-red q-mt-sm">1. Система не возвращает средства и изменения вступают в силу немедленно.</p>
    <p class="text-red q-mt-sm">2. Количество сред телефона и запусков новой среды изменится в соответствии с новым планом.</p>
    <p class="q-mt-sm">Вы хотите продолжить?</p>
    `,
  againOpen: 'Среда уже открыта другим пользователем и не может быть открыта снова. Хотите закрыть эту среду и открыть её заново?',
  envType: 'Тип среды',
  envProxyTip:
    'Профиль телефона обязательно должен быть настроен с действующим прокси; в противном случае доступ к профилю телефона будет невозможен!',
  envChineProxyTip:
    'Профиль телефона обязательно должен быть настроен с действующим прокси; в противном случае доступ к профилю телефона будет невозможен!',
  selectComputing: 'выбор мощности',
  languageTips: 'Создание языка мобильной системы на основе IP - адреса в соответствующей стране без включения настраиваемого языка',
  billingMethod: 'метод выставления счетов',
  selectBillingMethod: 'Выберите способ выставления счета',
  seq: '№',
  udpLable: 'Протокол UDP',
  udpTip:
    'Если не удаётся подключиться к сети, снимите галочку с UDP, это означает, что сеть не поддерживает UDP. По умолчанию галочка установлена для защиты вашего IP от утечки.',
  createEnvTip:
    'Купленные вами профили {name} достигли лимита ({num}). Для продолжения покупок вам необходимо пройти аутентификацию с использованием реального имени, чтобы снять это ограничение.',
  envChangeTip:
    'Количество в пакете представляет максимальное количество профилей облачных телефонов, которые вы можете создать. Каждый профиль облачного телефона требует облачных вычислительных мощностей для работы, что приведет к дополнительным затратам на облачные вычислительные мощности. Пожалуйста, примите это во внимание!',
  maxEnvTip:
    'Превышено общее количество доступных профилей для основной учетной записи. Пожалуйста, приобретите пакет профилей или обновите его до пакета с большим количеством профилей!',
  disabled: 'Отключено',
  disabledProfile: 'Отключить профиль',
  enableProfile: 'Включить профиль',
  batchDisabledProfile: 'Отключить профили массово',
  batchEnableProfile: 'Включить профили массово',
  createEnvMoneyTip:
    'Недостаточный баланс и ежемесячные вычислительные мощности. Пожалуйста, пополните счёт или купите дополнительные вычислительные мощности.'
}
