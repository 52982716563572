import { LocalStorage } from 'quasar'
import { DYNAMICPROXY } from 'src/utils/proxy'
class CacheValue {
  constructor(value, hours = 24) {
    this.value = value
    this.expired = Date.now() + hours * 60 * 60 * 1000
  }
}
// 登录时清除接口数据/v1/globalvaluesetting/getGlobalValue
const globalValueSettingList = [
  'bookmarks',
  'badge',
  'websiteCredentials',
  'websitesCredentials',
  'ipWhitelist',
  'RpaConcurrent',
  'SyncUserExtensions',
  'closeaction',
  'RpaRunningStrategy',
  'websitesCredential',
  'browserBaseSetting',
  'proxyTypelist',
  'globalValueTypelist'
]
// 清除缓存别名集合
export const DeleteCacheMap = {
  CustomExtensionList: 'CustomExtensionList',
  Extensions: 'ExtensionsCache',
  Preference: 'CommonPreferenceCache',
  Fingerprint: 'CommonFingerprintCache',
  ChildUserID: 'ChildUserIDCache'
}
// getChildrenListByName接口--需要持久缓存的列表
const storageList = [
  'resolution',
  'deviceMemory',
  'proxyType',
  'hardwareConcurrency',
  'ExtractIpVender',
  'AndroidResoluation',
  'IOSResoluation',
  'CoreVersionToUAVersion',
  'MacChromiumVersions',
  'platform',
  'ChromiumVersions',
  'IPCheckService',
  'FirefoxVersionToUAVersion'
]
// 持久缓存key的配置
export const StorageCachePrefix = {
  dict: 'EDict20_',
  needClear: [
    'EDict19_',
    'EDict18_',
    'EDict17_',
    'EDict16_',
    'EDict15_',
    'EDict14_',
    'EDict13_',
    'EDict12_',
    'EDict11_',
    'EDict10_',
    'EDict9_',
    'EDict8_',
    'EDict7_',
    'EDict6_'
  ]
}
/**
 * @description 缓存重复接口数据
 * @param key // 缓存键
 * @param value // 缓存值
 * @param hours // 过期时间
 * @param alias // 删除缓存的别名
 * @param storage // 持久缓存的key
 */
export class CacheMap {
  constructor() {
    this.cache = new Map()
    this.alias = new Map()
  }

  set(key, value, hours = 24, alias, storage) {
    if (storage && storageList.includes(alias)) {
      LocalStorage.set(storage, value)
    } else {
      this.cache.set(key, new CacheValue(value, hours))
      if (alias) {
        this.alias.set(alias, key)
      }
    }
  }

  get(key, storage) {
    const storageData = LocalStorage.getItem(storage)
    if (storageData) {
      return JSON.parse(storageData)
    } else {
      // console.log('get-------------', storage)
      const cacheValue = this.cache.get(key)
      // 没有过期
      if (cacheValue && cacheValue.expired > Date.now()) {
        // 如果返回是对象类型，则转换一下
        return JSON.parse(cacheValue.value)
      }
      // 过期删除key，返回null
      this.cache.delete(key)
    }
    return null
  }
  // 是否在当前缓存map对象中
  has(key) {
    return this.cache.has(key)
  }
  // 缓存个数
  size() {
    return this.cache.size
  }
  // 删除某个缓存
  delete(key) {
    this.cache.delete(key)
  }
  // 删除缓存
  deleteByAlias(alias) {
    const key = this.alias.get(alias)
    if (!key) return
    this.delete(key)
    this.alias.delete(alias)
  }
  // 清空
  clear() {
    this.cache.clear()
  }
  // 登陆时清空全局设置缓存数据
  clearGlobalValueSettingList() {
    globalValueSettingList.forEach(it => {
      this.deleteByAlias(it)
    })
  }
  // 登陆时清空全局代理信息
  clearFindByUserIdAndProxyType() {
    DYNAMICPROXY.forEach(it => {
      this.deleteByAlias(it)
    })
  }
  // 清除持久缓存
  removeStorage() {
    const storageKey = LocalStorage.getAllKeys()
    storageKey.forEach(item => {
      if (StorageCachePrefix.needClear.some(prefix => item.startsWith(prefix))) {
        LocalStorage.remove(item)
      }
    })
  }

  // 删除缓存
  // 获取所有启用的扩展list
  static DeleteExtensionCache() {
    cache.deleteByAlias(DeleteCacheMap.Extensions)
  }
  // 获取主账号下所有自定义扩展
  static DeleteCustomExtensionCache() {
    cache.deleteByAlias(DeleteCacheMap.CustomExtensionList)
  }
  // 常用偏好设置
  static DeleteCommonPreferenceCache() {
    cache.deleteByAlias(DeleteCacheMap.Preference)
  }
  // 指纹偏好设置
  static DeleteCommonFingerprintCache() {
    cache.deleteByAlias(DeleteCacheMap.Fingerprint)
  }
  // 子账号列表
  static DeleteChildUserIDCache() {
    cache.deleteByAlias(DeleteCacheMap.ChildUserID)
  }
  // 查询单个全局设置
  static DeleteGlobalValueCache(value) {
    cache.deleteByAlias(value)
  }
  // 根据用户ID和代理类型查询全局代理信息
  static DeleteProxyTypeCache(value) {
    cache.deleteByAlias(value)
  }
  // 获取字典子值list
  static DeleteChildrenDictsStorage() {
    cache.removeStorage()
  }
  // 登录时清除所有的全局设置
  static DeleteGlobalValueSettingList() {
    cache.clearGlobalValueSettingList()
  }
  // 登录时清除所有的全局代理信息
  static DeleteFindByUserIdAndProxyType() {
    cache.clearFindByUserIdAndProxyType()
  }
}

export const cache = new CacheMap()
